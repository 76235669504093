import { createTheme } from '@mui/material/styles'
import { DMSansMedium, DMSansRegular } from './font'
import { checkboxClasses } from '@mui/material/Checkbox'
import type {} from '@mui/x-data-grid/themeAugmentation'
import type {} from '@mui/lab/themeAugmentation'

declare module '@mui/material/styles' {
  interface Theme {
    typographyColor: {
      primaryText: React.CSSProperties['color']
      secondaryText: React.CSSProperties['color']
      accentColor: React.CSSProperties['color']
      errorText: React.CSSProperties['color']
      onActivePrimaryButton: React.CSSProperties['color']
      iconBasicColor: React.CSSProperties['color']
      onSuccess: React.CSSProperties['color']
      iconWarningColor: React.CSSProperties['color']
    }
    extraBackgrounds: {
      selectedItem: React.CSSProperties['color']
      paper01: React.CSSProperties['color']
      paper02: React.CSSProperties['color']
      activeChip: React.CSSProperties['color']
      inactiveChip: React.CSSProperties['color']
      activeToggleButton: React.CSSProperties['color']
    }
    extraColors?: {
      strokeDefault: React.CSSProperties['color']
      stroke01: React.CSSProperties['color']
    }
    celebrationBadge: {
      badgeBackground: React.CSSProperties['color']
      badgeText: React.CSSProperties['color']
      bannerBackground: React.CSSProperties['color']
    }
  }
  interface ThemeOptions {
    typographyColor: {
      primaryText: React.CSSProperties['color']
      secondaryText: React.CSSProperties['color']
      accentColor: React.CSSProperties['color']
      errorText: React.CSSProperties['color']
      onActivePrimaryButton: React.CSSProperties['color']
      iconBasicColor: React.CSSProperties['color']
      onSuccess: React.CSSProperties['color']
      iconWarningColor: React.CSSProperties['color']
    }
    extraBackgrounds: {
      selectedItem: React.CSSProperties['color']
      paper01: React.CSSProperties['color']
      paper02: React.CSSProperties['color']
      activeChip: React.CSSProperties['color']
      inactiveChip: React.CSSProperties['color']
      activeToggleButton: React.CSSProperties['color']
    }
    extraColors?: {
      strokeDefault: React.CSSProperties['color']
      stroke01: React.CSSProperties['color']
    }
    celebrationBadge: {
      badgeBackground: React.CSSProperties['color']
      badgeText: React.CSSProperties['color']
      bannerBackground: React.CSSProperties['color']
    }
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    title: TypographyStyleOptions | undefined
    alertText: TypographyStyleOptions | undefined
    body3: TypographyStyleOptions | undefined
    body4: TypographyStyleOptions | undefined
  }

  interface TypographyOptions {
    title: TypographyStyleOptions | undefined
    alertText: TypographyStyleOptions | undefined
    body3: TypographyStyleOptions | undefined
    body4: TypographyStyleOptions | undefined
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true
    alertText: true
    body3: true
    body4: true
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: 'DMSansRegular',
    h1: {
      '@font-face': DMSansMedium,
      fontFamily: 'DMSansMedium',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    h2: {},
    h3: {
      '@font-face': DMSansMedium,
      fontFamily: 'DMSansMedium',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    body1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    body3: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    body4: {
      fontVariantNumeric: 'lining-nums proportional-nums',
      fontFeatureSettings: 'dlig on',
      '@font-face': DMSansMedium,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    button: {
      '@font-face': DMSansMedium,
      fontFamily: 'DMSansMedium',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '0.16px',
      textTransform: 'none',
    },
    title: {
      '@font-face': DMSansMedium,
      fontFamily: 'DMSansMedium',
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    alertText: {
      '@font-face': DMSansMedium,
      fontFamily: 'DMSansMedium',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          color: '#565656',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
          '&:focus': {
            outline: 'none',
          },
        },
        row: {
          borderBottom: '1px solid #EEE',
        },
        root: {
          backgroundColor: '#FFF',
          color: '#2F2F2F',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',

          '.MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
          },
          '.MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '.MuiDataGrid-cell': {
            borderBottom: '0px',
            minHeight: 'unset !important',
            padding: '0px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
            '&:last-of-type': {
              paddingRight: '24px',
            },
          },
          '.three-lines': {
            display: '-webkit-box',
            maxHeight: '4em !important',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
            whiteSpace: 'unset !important',
          },
          '.MuiDataGrid-row': {
            padding: '16px 0px',
            alignItems: 'center',
            maxHeight: 'unset !important',
          },
          '.MuiDataGrid-columnHeader:first-of-type': {
            paddingLeft: '24px',
          },
          '.MuiDataGrid-columnHeader:last-of-type': {
            paddingRight: '24px',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '@font-face': DMSansMedium,
          fontFamily: 'DMSansMedium',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          letterSpacing: '0.16px',
          padding: '4px 16px',
          gap: '8px',
          '.MuiChip-icon': {
            margin: '0px',
          },
          '.MuiChip-label': {
            padding: '0px',
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title: 'h1',
          alertText: 'p',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': DMSansRegular,
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.20)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
          transition: '0.3s',
          '&:hover': {
            backgroundColor: '#F6F6F6',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '16px',
          alignItems: 'center',
          gap: '12px',
          '.MuiAlert-icon': {
            margin: '0px',
            padding: '0px',
          },
          '.MuiAlert-message': {
            padding: '0px',
          },
        },
        standardSuccess: {
          backgroundColor: '#EDFDED',
          color: '#15631F',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10);',
          '.MuiAlert-icon': {
            color: '#15631F',
          },
        },
        standardError: {
          backgroundColor: '#FDEDEF',
          color: '#9E1C1C',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiInputBase-root': {
            borderRadius: '8px',
            border: '1px',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineheight: 'normal',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          gap: '8px',
          '.MuiButton-endIcon': {
            margin: '0px',
          },
          '.MuiButton-startIcon': {
            margin: '0px',
          },
          '.MuiButton-startIcon > *:nth-of-type(1)': {
            fontSize: '24px',
          },
          '.MuiButton-endIcon > *:nth-of-type(1)': {
            fontSize: '24px',
          },
        },
        containedPrimary: {
          color: '#002B20',
          textTransform: 'unset',
          padding: '12px',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: '#01ECB4',
            color: '#002B20',
          },
          '&:disabled': {
            backgroundColor: '#B4B4B4',
            color: '#FFFFFF',
          },
        },
        outlinedPrimary: {
          display: 'flex',
          padding: '12px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          border: '1px solid #216243',
          color: '#216243',
          '&:hover': {
            border: '1px solid #216243',
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '32px 40px',
          borderRadius: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0px',
          overflowY: 'unset',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 400,
        },
        textColorSecondary: '#565656',
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'black',
          [`&.${checkboxClasses.checked}`]: {
            color: '#216243',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          '&.Mui-selected': {
            backgroundColor: '#EAFFFF',
            borderColor: '#01ECB4',
          },
          '&:hover': {
            color: '#000000',
            backgroundColor: '#EAFFFF',
            borderColor: '#01ECB4',
          },
        },
      },
    },
  },
  typographyColor: {
    primaryText: '#2F2F2F',
    secondaryText: '#565656',
    accentColor: '#216243',
    errorText: '#9E1C1C',
    onActivePrimaryButton: '#002B20',
    iconBasicColor: '#47504E',
    onSuccess: '#15631F',
    iconWarningColor: '#724E10',
  },
  extraBackgrounds: {
    selectedItem: '#01ECB4',
    paper01: '#FBFBFB',
    paper02: '#F6F6F6',
    activeChip: '#EDFDED',
    inactiveChip: '#FDEDEF',
    activeToggleButton: '#EAFFFF',
  },
  extraColors: {
    strokeDefault: '#798380',
    stroke01: '#EEEEEE',
  },
  celebrationBadge: {
    badgeBackground: '#F8F0FF',
    badgeText: '#B82EE8',
    bannerBackground: '#F2DEFB',
  },
  palette: {
    primary: {
      main: '#01ECB4',
    },
    secondary: {
      main: '#216243',
    },
    info: {
      main: '#798380',
    },
    error: {
      main: '#9E1C1C',
    },
  },
})
