import { gql } from '@apollo/client'

export const GET_PAYMENT_ORDERS = gql`
  query PaymentOrders(
    $page: Int!
    $limit: Int!
    $order: OrderOption!
    $orderBy: OrderByOption!
    $filters: FiltersPaymentOrderInput
  ) {
    getPaymentOrders(
      page: $page
      limit: $limit
      order: $order
      orderBy: $orderBy
      filters: $filters
    ) {
      total
      paymentOrders {
        id
        clientFullName
        clientPhoneNumber
        clientPhoneLada
        promoterFullName
        amount
        status
        referenceNumber
        disbursementDate
        dispersionAmount
        appliedDate
        createdAt
        updatedAt
        previousCreditId
      }
    }
  }
`

export const GET_CREDIT_BY_STATUS = gql`
  query GetCreditsByStatus(
    $page: Int!
    $limit: Int!
    $order: OrderOption!
    $orderBy: OrderByCreditOption!
    $filters: FiltersCreditsInput
  ) {
    getCreditsByStatus(
      page: $page
      limit: $limit
      order: $order
      orderBy: $orderBy
      filters: $filters
    ) {
      id
      clientName
      phone
      createdAt
      updatedAt
      amount
      speiNumber
      dispersionAmount
      pendingType
      createdBy
      promoterFullName
      previousCreditId
    }
  }
`

export const GET_PAYMENT_ORDERS_COUNT = gql`
  query GetPaymentOrdersCount($disbursedPaymentType: String) {
    getPaymentOrdersCount(disbursedPaymentType: $disbursedPaymentType) {
      applied
      disbursed
      pending
      rejected
      inReview
    }
  }
`
