import { GridRenderCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { RejectedTypeEnum, Rejection, RejectionStatusEnum } from 'types'
import {
  FormattedDateEnum,
  convertToMexicoCentralTime,
  formattedDate,
  removeAccents,
} from '../../../../utils'

export const prospectRejectedColumns: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'Nombre',
    flex: 1,
    valueGetter: (params) => {
      const { firstName, firstLastName, secondLastName } = params.row
      const fullName = removeAccents(`${firstName} ${firstLastName} ${secondLastName}`)
      return `${fullName}`
    },
    renderCell: ({ row }: GridRenderCellParams) => {
      const { firstName, firstLastName, secondLastName } = row
      return <div>{`${firstName} ${firstLastName} ${secondLastName}`}</div>
    },
  },
  {
    field: 'createdBy',
    headerName: 'Promotora',
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams) => {
      const { createdBy } = row
      return createdBy
    },
  },
  {
    field: 'createdAt',
    headerName: 'Fecha de solicitud',
    flex: 1,
    valueFormatter: ({ value }: GridValueFormatterParams) => {
      const mexicoCentralDate = convertToMexicoCentralTime(value as string)
      const date = formattedDate(mexicoCentralDate.toString(), FormattedDateEnum.SMALL)
      return date
    },
  },
  {
    field: 'rejections',
    headerName: 'Fecha de rechazo',
    flex: 1,
    valueFormatter: ({ value }: GridValueFormatterParams) => {
      dayjs.locale('es')
      const rejections = value
      const rejection = rejections.find(
        (obj: Rejection) => obj.status === RejectionStatusEnum.PENDING,
      )
      let date = ''
      try {
        const mexicoCentralDate = convertToMexicoCentralTime(rejection.createdAt as string)
        date = formattedDate(mexicoCentralDate.toString(), FormattedDateEnum.FULL)
        return date
      } catch (error) {
        console.log(value)
      }
      return date
    },
  },
  {
    field: 'rejectedType',
    headerName: 'Tipo de rechazo',
    cellClassName: 'three-lines',
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams) => {
      const { rejections } = row
      const rejection = rejections.find(
        (obj: Rejection) => obj.status === RejectionStatusEnum.PENDING,
      )
      const returnFormattedRejectedType = (rejection: string) => {
        switch (rejection) {
          case RejectedTypeEnum.AUTOMATIC_VERIFICATION:
            return 'Verificación Automática'

          case RejectedTypeEnum.MANUAL:
            return 'Manual'

          default:
            return 'Desconocido'
        }
      }
      return returnFormattedRejectedType(rejection?.type || '')
    },
  },
  {
    field: 'rejectedReason',
    headerName: 'Motivo',
    flex: 1,
    cellClassName: 'three-lines',
    valueGetter: ({ row }: GridRenderCellParams) => {
      const { rejections } = row
      const rejection = rejections.find(
        (obj: Rejection) => obj.status === RejectionStatusEnum.PENDING,
      )
      return rejection?.reason || 'Razón no disponible'
    },
  },
  {
    field: 'internalNote',
    headerName: 'Nota interna',
    flex: 1,
    cellClassName: 'three-lines',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { rejections } = row
      const rejection = rejections.find(
        (obj: Rejection) => obj.status === RejectionStatusEnum.PENDING,
      )
      return <div title={rejection?.internalReason || ''}>{rejection?.internalReason || '-'}</div>
    },
  },
]
