import { FilterList } from '@mui/icons-material'
import { FC, SyntheticEvent, useMemo, useState, useEffect } from 'react'
import { debounce } from 'lodash'
import Header from 'components/Header'
import { Box, Tab, Tabs } from '@mui/material'
import TabPanel from 'components/common/TabPanel'
import AutomatedVerification from './AutomatedVerification'
import { ClientsCount } from 'types'
import Pending from './Pending'
import Accepted from './Accepted'
import Rejected from './Rejected'
import { useQuery } from '@apollo/client'
import { GET_PROMOTERS_COUNT } from './queries'

enum TAB {
  VERIFICATION = 0,
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
}

const Prospects: FC = () => {
  const [filter, setFilter] = useState<string>('')
  const [currentTab, setCurrentTab] = useState<TAB>(TAB.VERIFICATION)
  const { loading, data } = useQuery(GET_PROMOTERS_COUNT, {
    fetchPolicy: 'no-cache',
  })

  const [clientsCount, setClientsCount] = useState<ClientsCount>({
    accepted: 0,
    automaticVerification: 0,
    pending: 0,
    rejected: 0,
  })

  const debounced = useMemo(() => {
    return debounce((text: string) => {
      handleSearchInput(text)
    }, 500)
  }, [])

  const handleSearchInput = (text: string) => {
    if (text.length >= 3) {
      setFilter(text)
    } else if (text.length === 0) {
      setFilter('')
    }
  }

  const handleChangeStep = (event: SyntheticEvent, newStep: number) => {
    setCurrentTab(newStep)
  }

  const handleFilterButton = () => {
    // TODO: add logic to filter prospects
    console.log('click button filter')
  }

  useEffect(() => {
    if (!loading && data) {
      setClientsCount(data?.getClientsCount)
    }
  }, [data, loading])

  return (
    <>
      <Header
        title="Prospectos"
        labelSearch="Buscar prospectos"
        handleSearch={debounced}
        button={{
          label: 'Filtros',
          handleButton: handleFilterButton,
          color: 'primary',
          icon: <FilterList />,
          variant: 'outlined',
        }}
      />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginTop: '40px',
          marginBottom: '24px',
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleChangeStep}
          textColor="secondary"
          indicatorColor="secondary"
          variant="fullWidth"
          aria-label="Prospects tabs"
        >
          <Tab
            label={`Verificación automatizada (${clientsCount.automaticVerification || '0'})`}
            {...a11yProps(TAB.VERIFICATION)}
          />
          <Tab label={`Pendientes (${clientsCount.pending || '0'})`} {...a11yProps(TAB.PENDING)} />
          <Tab label={`Aceptados (${clientsCount.accepted || '0'})`} {...a11yProps(TAB.ACCEPTED)} />
          <Tab
            label={`Rechazados (${clientsCount.rejected || '0'})`}
            {...a11yProps(TAB.REJECTED)}
          />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={TAB.VERIFICATION}>
        <AutomatedVerification
          filter={filter}
          actualStep={currentTab}
          totalItems={clientsCount.automaticVerification}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB.PENDING}>
        <Pending filter={filter} actualStep={currentTab} totalItems={clientsCount.pending} />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB.ACCEPTED}>
        <Accepted filter={filter} actualStep={currentTab} totalItems={clientsCount.accepted} />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB.REJECTED}>
        <Rejected filter={filter} actualStep={currentTab} totalItems={clientsCount.rejected} />
      </TabPanel>
    </>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default Prospects
