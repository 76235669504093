import { GridRenderCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid'
import {
  FormattedDateEnum,
  convertToMexicoCentralTime,
  formattedDate,
  removeAccents,
} from '../../../../utils'

export const automatedVerificationColumns: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'Nombre',
    flex: 1,
    valueGetter: (params) => {
      const { firstName, firstLastName, secondLastName } = params.row
      const fullName = removeAccents(`${firstName} ${firstLastName} ${secondLastName}`)
      return `${fullName}`
    },
    renderCell: ({ row }: GridRenderCellParams) => {
      const { firstName, firstLastName, secondLastName } = row
      return <div>{`${firstName} ${firstLastName} ${secondLastName}`}</div>
    },
  },
  {
    field: 'createdBy',
    headerName: 'Promotora',
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams) => {
      const { createdBy } = row
      return createdBy
    },
  },
  {
    field: 'createdAt',
    headerName: 'Fecha de solicitud',
    flex: 1,
    valueFormatter: ({ value }: GridValueFormatterParams) => {
      const mexicoCentralDate = convertToMexicoCentralTime(value as string)
      const date = formattedDate(mexicoCentralDate.toString(), FormattedDateEnum.FULL)
      return date
    },
  },
]
