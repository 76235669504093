import { ReactComponent as CelebrationIcon } from 'assets/celebration.svg'
import { theme } from 'theme/theme'
import styled from '@emotion/styled'

export const BadgeDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 6px 8px;
  border-radius: 999px;
  background-color: ${theme.celebrationBadge.badgeBackground};
  color: ${theme.celebrationBadge.badgeText};
  max-width: 164px;
`

export const StyledCelebrationIcon = styled(CelebrationIcon)`
  width: 16px;
  height: 16px;
  rect {
    fill: ${theme.celebrationBadge.badgeText};
  }
`
