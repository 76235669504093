import { BadgeDiv, StyledCelebrationIcon } from './styled'

interface CelebrationBadgeProps {
  text: string
}

export const CelebrationBadge = ({ text }: CelebrationBadgeProps) => {
  return (
    <BadgeDiv>
      <StyledCelebrationIcon />
      {text}
    </BadgeDiv>
  )
}
