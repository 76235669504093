import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid'
import DisbursedMenu from '../Disbursed/MenuButton'
import {
  convertToMexicoCentralTime,
  formatPhoneNumber,
  formattedDate,
  FormattedDateEnum,
} from 'utils'
import { PendingTypeEnum } from '../../../types'
import { Button } from '@mui/material'
import { CelebrationBadge } from 'components/common/CelebrationBadge'

export const columnsInReview = ({
  handleClickVerifyButton,
}: {
  handleClickVerifyButton: (id: string) => void
}) => {
  const pendingColumns: GridColDef[] = [
    {
      field: 'renewed',
      headerName: 'Renovado',
      flex: 1,
      filterable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        const { previousCreditId } = row
        const badgeText = previousCreditId ? 'Renovado' : 'Nuevo'

        return <CelebrationBadge text={badgeText} />
      },
    },
    {
      field: 'clientName',
      headerName: 'Nombre',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        const { clientName } = row
        return <div>{clientName}</div>
      },
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        const { phone } = row
        return <div>{formatPhoneNumber(phone)}</div>
      },
    },
    {
      field: 'promoterFullName',
      headerName: 'Promotor(a)',
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { promoterFullName } = row
        return promoterFullName || ''
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de Creación',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const mexicoCentralDate = convertToMexicoCentralTime(value as string)
        const date = formattedDate(mexicoCentralDate.toString(), FormattedDateEnum.FULL)
        return date
      },
    },
    {
      field: 'amount',
      headerName: 'Monto',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const amount = Number(value)
        const formatted = amount.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
        })
        return formatted + ' MXN'
      },
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      align: 'right',
      renderCell: ({ row }: GridRenderCellParams) => (
        <Button variant="outlined" onClick={() => handleClickVerifyButton(row.id)}>
          Verificar
        </Button>
      ),
    },
  ]

  return pendingColumns
}

export const columnsPending = () => {
  const pendingColumns: GridColDef[] = [
    {
      field: 'clientName',
      headerName: 'Nombre',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        const { clientName } = row
        return <div>{clientName}</div>
      },
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        const { phone } = row
        return <div>{formatPhoneNumber(phone)}</div>
      },
    },
    {
      field: 'promoterFullName',
      headerName: 'Promotor(a)',
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { promoterFullName } = row
        return promoterFullName || ''
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de Creación',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const mexicoCentralDate = convertToMexicoCentralTime(value as string)
        const date = formattedDate(mexicoCentralDate.toString(), FormattedDateEnum.FULL)
        return date
      },
    },
    {
      field: 'amount',
      headerName: 'Monto',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const amount = Number(value)
        const formatted = amount.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
        })
        return formatted + ' MXN'
      },
    },
  ]

  return pendingColumns
}

export const columnsDisbursed = (refetch: () => void, changeTab: (tab: number) => void) => {
  const columnsDisbursed: GridColDef[] = [
    {
      field: 'renewed',
      headerName: 'Renovado',
      flex: 1,
      filterable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        const { previousCreditId } = row
        const badgeText = previousCreditId ? 'Renovado' : 'Nuevo'

        return <CelebrationBadge text={badgeText} />
      },
    },
    {
      field: 'clientFullName',
      headerName: 'Nombre',
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { clientFullName, clientName } = row
        return `${clientFullName || clientName}`
      },
    },
    {
      field: 'phoneNumber',
      headerName: 'Teléfono',
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { clientPhoneNumber: paymentPhone, clientPhoneLada, phone: creditPhone } = row
        const clientPhone = creditPhone || `${clientPhoneLada} ${formatPhoneNumber(paymentPhone)}`
        return clientPhone
      },
    },
    {
      field: 'promoterFullName',
      headerName: 'Promotor(a)',
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { promoterFullName } = row
        return promoterFullName || ''
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de Creación',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const mexicoCentralDate = convertToMexicoCentralTime(value as string)
        const date = formattedDate(mexicoCentralDate.toString(), FormattedDateEnum.FULL)
        return date
      },
    },
    {
      // TODO: change this field when the backend is ready
      field: 'updatedAt',
      headerName: 'Fecha de Desembolso',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const mexicoCentralDate = convertToMexicoCentralTime(value as string)
        const date = formattedDate(mexicoCentralDate.toString(), FormattedDateEnum.FULL)
        return date
      },
    },
    {
      field: 'referenceNumber',
      headerName: 'No. de Referencia',
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { referenceNumber, speiNumber } = row
        return referenceNumber || speiNumber
      },
    },
    {
      field: 'amount',
      headerName: 'Monto',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const amount = Number(value)
        const formatted = amount.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
        })
        return formatted + ' MXN'
      },
    },
    {
      field: 'dispersionAmount',
      headerName: 'Dispersión',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const amount = Number(value)
        const formatted = amount.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
        })
        return formatted + ' MXN'
      },
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      align: 'right',
      getActions: ({ row }: GridRowParams) => [
        <DisbursedMenu
          key={row.id}
          payoutID={row.id}
          clientFullName={row.clientFullName}
          amount={row.amount}
          refetch={refetch}
          changeTab={changeTab}
          paymentDate={row.disbursementDate}
        />,
      ],
    },
  ]

  return columnsDisbursed
}

export const columnsAccepted = ({
  handleClickDisburseButton,
}: {
  handleClickDisburseButton: (data: { amount: number; id: string; clientFullName: string }) => void
}) => {
  const columns: GridColDef[] = [
    {
      field: 'renewed',
      headerName: 'Renovado',
      flex: 1,
      filterable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        const { previousCreditId } = row
        const badgeText = previousCreditId ? 'Renovado' : 'Nuevo'

        return <CelebrationBadge text={badgeText} />
      },
    },
    {
      field: 'clientFullName',
      headerName: 'Nombre',
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { clientFullName } = row
        return `${clientFullName}`
      },
    },
    {
      field: 'clientPhoneNumber',
      headerName: 'Teléfono',
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { clientPhoneNumber, clientPhoneLada } = row
        return `${clientPhoneLada} ${formatPhoneNumber(clientPhoneNumber)}`
      },
    },
    {
      field: 'promoterFullName',
      headerName: 'Promotor(a)',
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { promoterFullName } = row
        return promoterFullName || ''
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de Creación',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const mexicoCentralDate = convertToMexicoCentralTime(value as string)
        const date = formattedDate(mexicoCentralDate.toString(), FormattedDateEnum.FULL)
        return date
      },
    },
    {
      field: 'amount',
      headerName: 'Monto',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const amount = Number(value)
        const formatted = amount.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
        })
        return formatted + ' MXN'
      },
    },
    {
      field: 'dispersionAmount',
      headerName: 'Dispersión',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const amount = Number(value)
        const formatted = amount.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
        })
        return formatted + ' MXN'
      },
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      align: 'right',
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Button
            variant="outlined"
            onClick={() =>
              handleClickDisburseButton({
                id: row.id,
                amount: row.amount,
                clientFullName: row.clientFullName,
              })
            }
          >
            Desembolsar
          </Button>
        )
      },
    },
  ]

  return columns
}

export const columnsRejected = () => {
  const columns: GridColDef[] = [
    // {
    //   field: 'status',
    //   flex: 1,
    //   renderCell: ({ row }: GridRenderCellParams) => {
    //     const { clientName } = row
    //     return `${clientName}`
    //   },
    // },
    {
      field: 'clientName',
      headerName: 'Nombre',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        const { clientName } = row
        return `${clientName}`
      },
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        const { phone } = row
        return <div>{formatPhoneNumber(phone)}</div>
      },
    },
    {
      field: 'promoterFullName',
      headerName: 'Promotor(a)',
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { promoterFullName } = row
        return promoterFullName || ''
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Última actualización',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const mexicoCentralDate = convertToMexicoCentralTime(value as string)
        const date = formattedDate(mexicoCentralDate.toString(), FormattedDateEnum.FULL)
        return date
      },
    },
    {
      field: 'amount',
      headerName: 'Monto',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const amount = Number(value)
        const formatted = amount.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
        })
        return formatted + ' MXN'
      },
    },
    {
      field: 'pendingType',
      headerName: 'Razon de rechazo',
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        if (value === PendingTypeEnum.STP) {
          return 'Nombre del cliente no coincide con el nombre de la cuenta'
        } else return 'Rechazo manual'
      },
    },
  ]
  return columns
}
