import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import Header from 'components/Header'
import { Menu, Tab, Tabs } from '@mui/material'
import { StyledBox } from '../../components/common/styled'
import TabPanel from 'components/common/TabPanel'
import { PaymentOrderStep, PaymentSortModel, PaymentType } from 'types'
import PendingReview from './PendingReview'
import Accepted from './Accepted'
import Disbursed from './Disbursed'
import Rejected from './Rejected'
import { debounce } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_PAYMENT_ORDERS_COUNT } from './queries'
import 'react-day-picker/dist/style.css'
import { DateRange } from 'react-day-picker'
import FatherMenu from './FatherMenu'
import CalendarMenu from './CalendarMenu'
import Pending from './Pending'

export const OPTIONS_ROWS_PER_PAGE = [20, 30, 50]
export const ORDER = 'desc'
export const ORDER_BY_CREATED_AT = 'createdAt'

enum TAB {
  PENDING_REVIEW = 0,
  PENDING = 1,
  APPLIED = 2,
  DISBURSED = 3,
  REJECTED = 4,
}

const getTabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const PaymentsOrders: FC = () => {
  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.SPEI)
  const [currentTab, setCurrentTab] = useState<PaymentOrderStep>(PaymentOrderStep.PENDING_REVIEW)
  const [filter, setFilter] = useState<string>('')

  const [fetchCount, { refetch: totalColumnsRefetch }] = useLazyQuery(GET_PAYMENT_ORDERS_COUNT, {
    fetchPolicy: 'no-cache',
    variables: {
      disbursedPaymentType: paymentType,
    },
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [range, setRange] = useState<DateRange | undefined>()
  const [showAllDates, setShowAllDates] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(Boolean(anchorEl))
  const [sortDateModel, setSortDateModel] = useState<string | null>(PaymentSortModel.CREATION)
  const [openSubMenu, setOpenSubMenu] = useState<boolean>(Boolean(anchorEl))
  const [paymentCount, setPaymentCount] = useState({
    inReview: 0,
    pending: 0,
    applied: 0,
    disbursed: 0,
    rejected: 0,
  })

  const handleCloseFatherMenu = () => {
    setOpen(false)
  }

  const handleCloseChildrenMenu = () => {
    setOpenSubMenu(false)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAllDates(event.target.checked)
    range && setRange(undefined)
  }

  const handleChangeStep = (event: SyntheticEvent, newStep: number) => {
    setRange(undefined)
    setCurrentTab(newStep)
    setShowAllDates(true)
  }

  const handleSwitchOption = () => {
    setRange(undefined)
    setShowAllDates(true)
  }

  const handleSearchInput = (text: string) => {
    if (text.length >= 3) {
      setFilter(text)
    } else {
      setFilter('')
    }
  }

  const debounced = useCallback(debounce(handleSearchInput, 500), [])

  const handleGoBackMenu = () => {
    setOpenSubMenu(false)
    setOpen(true)
  }

  const handleSelectSortModel = (sortModel: string | null) => {
    setSortDateModel(sortModel)
    setOpen(false)
    setOpenSubMenu(true)
  }

  const handleFilterButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const changeTab = (tab: number) => {
    setCurrentTab(tab)
    totalColumnsRefetch()
  }

  const handleDateChange = (data: DateRange | undefined) => {
    setRange(data)
    if (!data) {
      return
    }
    if (showAllDates && data?.from && data.to) {
      setShowAllDates(false)
    }
    if (range && range.from && range.to) {
      const currentStartDate = new Date(range.from)
      const currentEndDate = new Date(range.to)

      if (data.from && data.to) {
        const newStartDate = new Date(data.from)
        const newEndDate = new Date(data.to)
        newEndDate.setHours(23, 59, 59)
        if (newStartDate >= currentStartDate && newEndDate <= currentEndDate) {
          setRange({
            from: newStartDate,
            to: newEndDate,
          })
        } else if (newStartDate < currentStartDate && newStartDate < currentEndDate) {
          setRange({
            from: newStartDate,
            to: undefined,
          })
        } else if (newEndDate > currentEndDate && newEndDate > currentStartDate) {
          setRange({
            from: newEndDate,
            to: undefined,
          })
        }
      }
    }
  }

  useEffect(() => {
    const getCount = async () => {
      try {
        const response = await fetchCount()
        if (response?.data) {
          setPaymentCount(response?.data?.getPaymentOrdersCount)
        } else {
          throw new Error('Error al obtener el conteo de las órdenes de pago')
        }
      } catch (error) {
        console.log(error)
      }
    }

    getCount()
  }, [paymentType])

  return (
    <>
      <Header
        title="Órdenes de pago"
        labelSearch={
          currentTab === PaymentOrderStep.PENDING_REVIEW || currentTab === PaymentOrderStep.PENDING
            ? 'Buscar cliente'
            : 'Buscar cliente o número de referencia'
        }
        inputWidth={currentTab !== PaymentOrderStep.PENDING_REVIEW ? 390 : undefined}
        handleSearch={debounced}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseFatherMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <FatherMenu
          step={currentTab}
          handleSelectSortModel={handleSelectSortModel}
          handleSwitchOption={handleSwitchOption}
        />
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openSubMenu}
        onClose={handleCloseChildrenMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <CalendarMenu
          handleGoBackMenu={handleGoBackMenu}
          handleCheckboxChange={handleCheckboxChange}
          showAllDates={showAllDates}
          range={range}
          handleDateChange={handleDateChange}
        />
      </Menu>
      <StyledBox>
        <Tabs
          value={currentTab}
          onChange={handleChangeStep}
          textColor="secondary"
          indicatorColor="secondary"
          variant="fullWidth"
          aria-label="Payments tabs"
        >
          <Tab
            label={`En revisión (${paymentCount.inReview || '0'})`}
            {...getTabProps(PaymentOrderStep.PENDING_REVIEW)}
          />
          <Tab
            label={`Pendiente (${paymentCount.pending || '0'})`}
            {...getTabProps(PaymentOrderStep.PENDING)}
          />
          <Tab
            label={`Aceptados (${paymentCount.applied || '0'})`}
            {...getTabProps(PaymentOrderStep.APPLIEDS)}
          />
          <Tab
            label={`Desembolsados (${paymentCount.disbursed || '0'})`}
            {...getTabProps(PaymentOrderStep.DISBURSEDS)}
          />
          <Tab
            label={`Rechazados (${paymentCount.rejected || '0'})`}
            {...getTabProps(PaymentOrderStep.REJECTED)}
          />
        </Tabs>
      </StyledBox>
      <TabPanel value={currentTab} index={TAB.PENDING_REVIEW}>
        <PendingReview
          inputFilter={filter}
          sortModel={sortDateModel}
          dateRange={range}
          handleFilterButton={handleFilterButton}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB.PENDING}>
        <Pending
          inputFilter={filter}
          sortModel={sortDateModel}
          dateRange={range}
          handleFilterButton={handleFilterButton}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB.APPLIED}>
        <Accepted
          changeTab={changeTab}
          inputFilter={filter}
          dateRange={range}
          handleFilterButton={handleFilterButton}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB.DISBURSED}>
        <Disbursed
          changeTab={changeTab}
          inputFilter={filter}
          sortDateModel={sortDateModel}
          dateRange={range}
          handleFilterButton={handleFilterButton}
          totalItems={paymentCount.disbursed || 0}
          paymentType={paymentType}
          onChangeInputFilter={(inputFilter: string) => setFilter(inputFilter)}
          onChangePaymentType={(paymentType: PaymentType) => setPaymentType(paymentType)}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB.REJECTED}>
        <Rejected inputFilter={filter} dateRange={range} handleFilterButton={handleFilterButton} />
      </TabPanel>
    </>
  )
}

export default PaymentsOrders
